<template>
  <div class="left-menu">
    <el-aside width="auto">
      <el-menu default-active="overview" class="el-menu-ul" :collapse-transition="false" router :collapse="isCollapse">
        <el-menu-item index="/dashboard/overview">
          <i class="el-icon-monitor"></i>
          <span slot="title">数据概览</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/hospital">
          <i class="el-icon-school"></i>
          <span slot="title">医院管理</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/area">
          <i class="el-icon-guide"></i>
          <span slot="title">病区管理</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/nurse">
          <i class="el-icon-user"></i>
          <span slot="title">护士管理</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/device">
          <i class="el-icon-refrigerator"></i>
          <span slot="title">设备管理</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/record">
          <i class="el-icon-notebook-2"></i>
          <span slot="title">租借记录</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/article">
          <i class="el-icon-document-remove"></i>
          <span slot="title">文章管理</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/video">
          <i class="el-icon-video-camera"></i>
          <span slot="title">视频管理</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/users">
          <i class="el-icon-star-off"></i>
          <span slot="title">用户列表</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <div class="toggle-menu">
      <el-switch v-model="isCollapse" active-color="#13ce66" inactive-color="#ff4949" :active-value="false" :inactive-value="true"></el-switch>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data () {
    return {
      isCollapse: false
    }
  }
}
</script>

<style scoped>
.el-aside {
  background-color: #FFFFFF;
  color: #333;
  text-align: left;
  line-height: 200px;
  height: calc( 100vh - 40px);
}
.el-aside::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 4px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.el-aside::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius   : 10px;
  background-color: #409EFF;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
.el-aside::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #ededed;
  border-radius: 10px;
}
.el-aside .el-menu {
  border-right: 0;
}
.left-menu .toggle-menu {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-menu-ul:not(.el-menu--collapse) {
  width: 200px;
}
</style>
