<template>
  <div class="common-header">
    <el-header>Header</el-header>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
.el-header {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
</style>
