<template>
  <div class="common-footer">
    <el-footer>Footer</el-footer>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
</style>
