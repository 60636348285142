<template>
  <div class="dashboard-page">
    <el-container class="view-container">
      <left-menu></left-menu>
      <el-container class="view-right-container">
        <top-header></top-header>
        <el-main>
          <router-view></router-view>
        </el-main>
        <bottom-footer></bottom-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import leftMenu from '../components/common/Menu'
import topHeader from '../components/common/Header'
import bottomFooter from '../components/common/Footer'
export default {
  name: 'Dashboard',
  components: {
    leftMenu,
    topHeader,
    bottomFooter
  }
}
</script>

<style scoped>
.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: left;
}
.view-container{
  height: 100vh;
  width: 100%;
}
.view-right-container{
  display: flex!important;
  flex-direction: column!important;
}
</style>
